:root {
  --color: #fff;
  --background: #000;
  --background2: #222;
  --highlight: #000;
  --updated: #555;
  --advanced: #4a7e53;
  --row: 100px;
  --gap: 5px;
  --font: "syne", "Lexend Peta", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  --fontsize: 38px;
  --font-numbers: "Roboto Mono", "Lexend Peta", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  --space: 20px;
  --space-sm: 5px;
  --space-bg: 40px;
  --radius: 3px;
  --easing: cubic-bezier(0, 1, 0, 1);
  --easing: ease;
  --animation: 1s;
  --hon: #f00;
  --kaw: #0f0;
  --gas: #c00;
  --yam: #44f;
  --ktm: #ff7e00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font: bold var(--fontsize)/1.5em var(--font);
  user-select: none;
}

body {
  color: var(--color);
  background: var(--background);
}

.header {
  padding: var(--space) var(--space-bg);
  border-bottom: 2px solid var(--background2);
  text-transform: uppercase;
  font-weight: 800;
}

.results__header {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--space-bg);
  gap: var(--space);
}
.results__headerlabel {
  text-align: right;
  flex-shrink: 0;
}
.results__headerlabel--best {
  flex-shrink: 0;
}
.results__headerlabel--last {
  flex-shrink: 0;
  flex-basis: 20vw;
}
.results__body {
  position: relative;
}

.rider {
  position: absolute;
  top: calc(var(--row) * (var(--position) - 1) + (var(--position) - 1) * var(--gap));
  left: 0;
  right: 0;
  height: var(--row);
  display: flex;
  gap: var(--space);
  padding: 0 var(--space-bg);
  background: var(--background2);
  align-items: center;
  transition: top var(--animation) var(--easing), background-color 3s ease-out;
}
.rider--updated {
  z-index: 100;
  background: var(--updated);
  transition: top var(--animation) var(--easing);
}
.rider--advanced {
  z-index: 100;
  background: var(--advanced);
  transition: top var(--animation) var(--easing);
}
.rider__position {
  color: var(--background);
  background: var(--color);
  width: 50px;
  height: 50px;
  font-family: var(--font-numbers);
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: 800;
  flex-shrink: 0;
}
.rider__country {
  flex-basis: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--radius);
  overflow: hidden;
  display: none;
}
.rider__bike {
  flex-shrink: 0;
  width: 10px;
  height: 50px;
  border-radius: var(--radius);
}
.rider__bike--gasgas {
  background: var(--gas);
}
.rider__bike--husqvarna {
  background: var(--hus);
}
.rider__bike--honda {
  background: var(--hon);
}
.rider__bike--yamaha {
  background: var(--yam);
}
.rider__bike--kawasaki {
  background: var(--kaw);
}
.rider__bike--ktm {
  background: var(--ktm);
}
.rider__name {
  flex-grow: 1;
  font-size: 1.25em;
  line-height: 1em;
}
.rider__lastname {
  text-transform: uppercase;
  font-weight: 800;
}
.rider__best, .rider__last {
  font-family: var(--font-numbers);
  text-align: right;
  align-items: center;
  white-space: nowrap;
}
.rider__best {
  flex-shrink: 0;
  color: var(--background);
  background: var(--color);
  padding: var(--space-sm) var(--space) var(--space-sm) calc(var(--space) * 1.5);
  margin: var(--space) 0;
  border-radius: calc(var(--radius) * 4);
}
.rider__last {
  flex-shrink: 0;
  flex-basis: 20vw;
}
.rider .number {
  font-size: 1.5em;
  font-weight: 600;
}
.rider .unit {
  opacity: 0.5;
  font-weight: 600;
}

@media (max-width: 1200px) {
  :root {
    --fontsize: 3vw;
    --space: 10px;
    --row: 10vw;
  }

  body {
    border-radius: 10px;
  }

  .rider__position {
    width: 6vw;
    height: 6vw;
    font-size: 3vw;
  }
  .rider__bike {
    height: 6vw;
  }
  .rider__best {
    border-radius: 3px;
  }

  .rider__firstname {
    display: none !important;
  }

  .rider {
    padding: 0 var(--space);
    background: rgba(0, 0, 0, 0.5);
  }
}
.intermission {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: none;
}

.paused .intermission {
  display: flex;
}